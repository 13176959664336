export const _products = {
  transWonders: "TransWonders",
  accountsWonders: "AccountsWonders",
};
export const _roles = {
  superAdmin: "SuperAdmin",
  admin: "Admin",
  clientAdmin: "ClientAdmin",
  clientUser: "ClientUser",
};
