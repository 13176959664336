import React from "react";

//Auth Part
const RegisterUsers = React.lazy(() =>
  import("../pages/shared/auth/RegisterUsers")
);
const ChangePassword = React.lazy(() =>
  import("../pages/shared/auth/ChangePassword")
);

//wallets
const ManageClientWallets = React.lazy(() =>
  import("../pages/shared/wallets/ManageClientWallets")
);
const UpdateCredit = React.lazy(() =>
  import("../pages/shared/wallets/UpdateCredit")
);
const TransactionHistoryGrid = React.lazy(() =>
  import("../pages/shared/wallets/TransactionHistoryGrid")
);
//dashboard part
const DashBoard = React.lazy(() => import("../pages/shared/dashboard/Index"));
const Company = React.lazy(() => import("../pages/shared/masters/Company"));
const Groups = React.lazy(() =>
  import("../pages/shared/masters/groups/GroupsGrid")
);
const CreateGroups = React.lazy(() =>
  import("../pages/shared/masters/groups/CreateGroups")
);
const Ledgers = React.lazy(() =>
  import("../pages/shared/masters/ledgers/LedgersGrid")
);
const CreateLedgers = React.lazy(() =>
  import("../pages/shared/masters/ledgers/CreateLedgers")
);
const RetireLedgersGrid = React.lazy(() =>
  import("../pages/shared/masters/ledgers/RetireLedgersGrid")
);
const VehicleGrid = React.lazy(() =>
  import("../pages/products/transwonders/masters/vehicles/VehicleGrid")
);
const CreateVehicle = React.lazy(() =>
  import("../pages/products/transwonders/masters/vehicles/CreateVehicles")
);
const VehicleTypeGrid = React.lazy(() =>
  import("../pages/products/transwonders/masters/vehicletype/VehicleTypeGrid")
);
const CreateVehicleType = React.lazy(() =>
  import("../pages/products/transwonders/masters/vehicletype/CreateVehicleType")
);
const RouteGrid = React.lazy(() =>
  import("../pages/products/transwonders/masters/routees/RouteGrid")
);
const CreateRoute = React.lazy(() =>
  import("../pages/products/transwonders/masters/routees/CreateRoute")
);
const CostCenterGrid = React.lazy(() =>
  import("../pages/shared/masters/costcenter/CostCenterGrid")
);
const CreateCostCenter = React.lazy(() =>
  import("../pages/shared/masters/costcenter/CreateCostCenter")
);

//Voucher Settings
const ConsignmentInvoiceSettings = React.lazy(() =>
  import(
    "../pages/products/transwonders/vouchersettings/ConsignmentInvoiceSettings"
  )
);
const CommissionSlipSettings = React.lazy(() =>
  import(
    "../pages/products/transwonders/vouchersettings/CommissionSlipSettings"
  )
);

const ConsingmentNoteSettings = React.lazy(() =>
  import(
    "../pages/products/transwonders/vouchersettings/ConsingmentNoteSettings"
  )
);

const ConsignmentNoteUncheckedSettings = React.lazy(() =>
  import(
    "../pages/products/transwonders/vouchersettings/ConsignmentNoteUncheckedSettings"
  )
);

const CreditNoteSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/CreditNoteSettings")
);
const DebitNoteSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/DebitNoteSettings")
);
const DualTransactionPaymentSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/DualTransactionPaymentSettings")
);
const DualTransctionReceiptSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/DualTransctionReceiptSettings")
);
const JournalSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/JournalSettings")
);
const ContraSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ContraSettings")
);
const PaymentSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/PaymentSettings")
);
const ReceiptSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ReceiptSettings")
);
const ProformaSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ProformaSettings")
);
const PurchaseEntry = React.lazy(() =>
  import("../pages/shared/vouchersettings/PurchaseEntrySettings")
);
const SalesInvoice = React.lazy(() =>
  import("../pages/shared/vouchersettings/SalesInvoiceSettings")
);

//Account Transaction
const SalesInvoiceGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/salesinvoice/SalesInvoiceGrid"
  )
);

const CreateSalesInvoice = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/salesinvoice/CreateSalesInvoice"
  )
);
const ProformaInvoiceGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/proformainvoice/ProformaInvoiceGrid"
  )
);

const CreateProformaInvoice = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/proformainvoice/CreateProformaInvoice"
  )
);
const PurchaseEntryGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/purchaseentry/PurchaseEntryGrid"
  )
);

const CreatePaymentReceipt = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/paymentreceipt/CreatePaymentReceipt"
  )
);
const CreatePurchaseEntry = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/purchaseentry/CreatePurchaseEntry"
  )
);
const PaymentReceiptGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/paymentreceipt/PaymentReceiptGrid"
  )
);
const JournalContraGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/journalcontra/JournalContraGrid"
  )
);
const CreateJournalContra = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/journalcontra/CreateJournalContra"
  )
);
const DualTransactionGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/dualtransaction/DualTransactionGrid"
  )
);
const CreateDualTransaction = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/dualtransaction/CreateDualTransaction"
  )
);
const DebitCreditNoteGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/debitnotecreditnote/DebitCreditNoteGrid"
  )
);
const CreateDebitCreditNote = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/debitnotecreditnote/CreateDebitCreditNote"
  )
);
//TransPortation Transaction
const ProgrammeGrid = React.lazy(() =>
  import("../pages/products/transwonders/transaction/programme/ProgrammeGrid")
);
const CreateProgramme = React.lazy(() =>
  import("../pages/products/transwonders/transaction/programme/CreateProgramme")
);
const CreateConsignmentNote = React.lazy(() =>
  import(
    "../pages/products/transwonders/transaction/consignmentnote/CreateConsignmentNote"
  )
);
const UpdateConsignmentNote = React.lazy(() =>
  import(
    "../pages/products/transwonders/transaction/consignmentnote/UpdateConsignmentNote"
  )
);
const CnCsGrid = React.lazy(() =>
  import("../pages/products/transwonders/transaction/CnCs/CnCsGrid")
);
const InvoiceGrid = React.lazy(() =>
  import("../pages/products/transwonders/transaction/invoice/InvoiceGrid")
);

const GenerateInvoice = React.lazy(() =>
  import("../pages/products/transwonders/transaction/invoice/GenerateInvoice")
);
const BalanceRegisterGrid = React.lazy(() =>
  import(
    "../pages/products/transwonders/transaction/balanceRegister/BalanceRegisterGrid"
  )
);
const VehicleTripGrid = React.lazy(() =>
  import(
    "../pages/products/transwonders/transaction/vehicletrip/VehicleTripGrid"
  )
);

const CreateVehicleTrip = React.lazy(() =>
  import(
    "../pages/products/transwonders/transaction/vehicletrip/CreateVehicleTrip"
  )
);

const VehicleTripSettings = React.lazy(() =>
  import("../pages/products/transwonders/vouchersettings/VehicleTripSettings")
);

//Reports
const LedgerReports = React.lazy(() =>
  import("../pages/shared/reports/LedgerReports")
);
const TrailBalance = React.lazy((res) =>
  import("../pages/shared/reports/TrailBalance")
);
const ProfitLoss = React.lazy(() =>
  import("../pages/shared/reports/ProfitLoss")
);
const BalanceSheet = React.lazy(() =>
  import("../pages/shared/reports/BalanceSheet")
);
const FreightRegister = React.lazy(() =>
  import("../pages/products/transwonders/reports/FreightRegister")
);

//GST
const GstAuthentication = React.lazy(() =>
  import("../pages/shared/gst/GstAuthentication")
);
const AllGstServices = React.lazy(() =>
  import("../pages/shared/gst/AllGstServices")
);
const GSTR1Services = React.lazy(() =>
  import("../pages/shared/gst/components/Gstr1Components/GSTR1Services")
);
const CreateChallan=React.lazy(()=> import("../pages/shared/challan/CreateChallan"));
//Eway Bill
const GenerateEwayBillGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/GenerateEwayBillGrid")
);
const GenerateEwayBill = React.lazy(() =>
  import("../pages/shared/ewaybill/GenerateEwayBill")
);
const BillsByDateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/BillsByDateGrid")
);
const TransporterByDateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/TransporterByDateGrid")
);
const TransporterByStateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/TransporterByStateGrid")
);
//CancelEwayBill
const CancelEwayBill = React.lazy(() =>
  import("../pages/shared/ewaybill/CancelEwayBill")
);
//MultiReport Printing
const ReportPrinting = React.lazy(() =>
  import("../pages/products/transwonders/reportprinting/ReportPrinting")
);
const TransWondersRoutes = [
  {
    path: "/dashboard/register_users",
    name: "registerUsers",
    element: RegisterUsers,
  },
  {
    path: "/dashboard/change_password",
    name: "changepassword",
    element: ChangePassword,
  },
  {
    path: "/dashboard/wallet_manageusers",
    name: "managewallets",
    element: ManageClientWallets,
  },
  {
    path: "/dashboard/wallet_updatecredit",
    name: "updatecredit",
    element: UpdateCredit,
  },
  {
    path: "/dashboard/wallet_TransactionHistoryGrid",
    name: "TransactionHistoryGrid",
    element: TransactionHistoryGrid,
  },

  {
    path: "/dashboard/dshboardhome",
    name: "dashboard",
    element: DashBoard,
  },
  {
    path: `/dashboard/masters_company`,
    name: "company",
    element: Company,
  },
  {
    path: `/dashboard/masters_groups`,
    name: "groups",
    element: Groups,
  },
  {
    path: `/dashboard/masters_creategroups`,
    name: "create groups",
    element: CreateGroups,
  },
  {
    path: `/dashboard/masters_ledgers`,
    name: "ledgers",
    element: Ledgers,
  },
  {
    path: `/dashboard/masters_createledgers`,
    name: "create ledgers",
    element: CreateLedgers,
  },
  {
    path: `/dashboard/masters_retireledgers`,
    name: "Retired ledgers",
    element: RetireLedgersGrid,
  },
  {
    path: `/dashboard/masters_vehicles`,
    name: "vehicles",
    element: VehicleGrid,
  },
  {
    path: `/dashboard/masters_createvehicles`,
    name: "create vehicles",
    element: CreateVehicle,
  },
  {
    path: `/dashboard/masters_vehicletypegrid`,
    name: "vehicle type grid",
    element: VehicleTypeGrid,
  },
  {
    path: `/dashboard/masters_createvehicletype`,
    name: "create vehicle type",
    element: CreateVehicleType,
  },
  {
    path: `/dashboard/masters_routesgrid`,
    name: "routes",
    element: RouteGrid,
  },
  {
    path: `/dashboard/masters_createroute`,
    name: "routes",
    element: CreateRoute,
  },
  {
    path: `/dashboard/masters_costcenterGrid`,
    name: "costcentergrid",
    element: CostCenterGrid,
  },
  {
    path: `/dashboard/masters_createcostcenter`,
    name: "costcentergrid",
    element: CreateCostCenter,
  },
  {
    path: `/dashboard/masters_createcostcenter`,
    name: "costcentergrid",
    element: CreateCostCenter,
  },

  //Voucher Settings

  {
    path: `/dashboard/voucher_settings_consignmentinvoice_Settings`,
    name: "consignmentinvoicesettings",
    element: ConsignmentInvoiceSettings,
  },
  {
    path: `/dashboard/voucher_settings_commisionslip_settings`,
    name: "commissionslipsettings",
    element: CommissionSlipSettings,
  },
  {
    path: `/dashboard/voucher_settings_consignmentnote_settings`,
    name: "consingmentnotesettings",
    element: ConsingmentNoteSettings,
  },
  {
    path: `/dashboard/voucher_settings_consignmentNote_unchecked_settings`,
    name: "consignmentnoteunchecked",
    element: ConsignmentNoteUncheckedSettings,
  },
  {
    path: `/dashboard/voucher_settings_creditnote_settings`,
    name: "creditnotesettings",
    element: CreditNoteSettings,
  },
  {
    path: `/dashboard/voucher_settings_debitnote_settings`,
    name: "debitnotesettings",
    element: DebitNoteSettings,
  },
  {
    path: `/dashboard/voucher_settings_dualtransactionpayment_settings`,
    name: "dualtransactionpaymentsettings",
    element: DualTransactionPaymentSettings,
  },
  {
    path: `/dashboard/voucher_settings_dualtransactionreceipt_settings`,
    name: "dualtransctionreceiptsettings",
    element: DualTransctionReceiptSettings,
  },
  {
    path: `/dashboard/voucher_settings_journal_settings`,
    name: "journalsettings",
    element: JournalSettings,
  },
  {
    path: `/dashboard/voucher_settings_contra_settings`,
    name: "contrasettings",
    element: ContraSettings,
  },
  {
    path: `/dashboard/voucher_settings_payment_settings`,
    name: "paymentsettings",
    element: PaymentSettings,
  },
  {
    path: `/dashboard/voucher_settings_receipt_settings`,
    name: "receiptsettings",
    element: ReceiptSettings,
  },
  {
    path: `/dashboard/voucher_settings_proforma_settings`,
    name: "proformasettings",
    element: ProformaSettings,
  },
  {
    path: `/dashboard/voucher_settings_purchase_settings`,
    name: "purchaseentry",
    element: PurchaseEntry,
  },
  {
    path: `/dashboard/voucher_settings_salesinvoice_settings`,
    name: "salesinvoice",
    element: SalesInvoice,
  },
  {
    path: `/dashboard/voucher_settings_vehicletrip_settings`,
    name: "vehicletripsettings",
    element: VehicleTripSettings,
  },

  //transaction
  {
    path: `/dashboard/transaction/accounttransaction_SalesInvoice`,
    name: "salesinvoicegrid",
    element: SalesInvoiceGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_CreateSalesInvoice`,
    name: "createsalesinvoice",
    element: CreateSalesInvoice,
  },
  {
    path: `/dashboard/transaction/accounttransaction_proformainvoice`,
    name: "proformainvoicegrid",
    element: ProformaInvoiceGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createproformainvoice`,
    name: "createproformainvoice",
    element: CreateProformaInvoice,
  },
  {
    path: `/dashboard/transaction/accounttransaction_purchaseentryinvoice`,
    name: "purchaseentrygrid",
    element: PurchaseEntryGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createpurchaseentry`,
    name: "createpurchaseentry",
    element: CreatePurchaseEntry,
  },
  {
    path: `/dashboard/transaction/accounttransaction_paymentreceiptinvoice`,
    name: "paymentreceiptgrid",
    element: PaymentReceiptGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createPaymentReceipt`,
    name: "createpaymentreceipt",
    element: CreatePaymentReceipt,
  },
  {
    path: `/dashboard/transaction/accounttransaction_jpurnalcontrainvoice`,
    name: "journalcontragrid",
    element: JournalContraGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createjournalcontra`,
    name: "createjournalcontra",
    element: CreateJournalContra,
  },
  {
    path: `/dashboard/transaction/accounttransaction_dualtransactioninvoice`,
    name: "dualtransactiongrid",
    element: DualTransactionGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_Createdualtransaction`,
    name: "createDualTransaction",
    element: CreateDualTransaction,
  },
  {
    path: `/dashboard/transaction/accounttransaction_debitcreditnoteinvoice`,
    name: "debitcreditnotegrid",
    element: DebitCreditNoteGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createdebitcreditnote`,
    name: "createdebitcreditnote",
    element: CreateDebitCreditNote,
  },
  //Transportation Transaction
  {
    path: `/dashboard/transaction/transportationtransaction_programme`,
    name: "programmegrid",
    element: ProgrammeGrid,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_createprogramme`,
    name: "createprogramme",
    element: CreateProgramme,
  },

  {
    path: `/dashboard/transaction/transportationtransaction_createconsignmentnote`,
    name: "createconsignmentnote",
    element: CreateConsignmentNote,
  },

  {
    path: `/dashboard/transaction/transportationtransaction_updateconsignmentnote`,
    name: "updateconsignmentnote",
    element: UpdateConsignmentNote,
  },

  {
    path: `/dashboard/transaction/transportationtransaction_cncs`,
    name: "cncsgrid",
    element: CnCsGrid,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_invoice`,
    name: "cncsgrid",
    element: InvoiceGrid,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_generateinvoice`,
    name: "generateinvoice",
    element: GenerateInvoice,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_balanceRegister`,
    name: "balanceregister",
    element: BalanceRegisterGrid,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_vehicletrip`,
    name: "vehicletrip",
    element: VehicleTripGrid,
  },
  {
    path: `/dashboard/transaction/transportationtransaction_createvehicletrip`,
    name: "createvehicletrip",
    element: CreateVehicleTrip,
  },

  //Reports
  {
    path: `/dashboard/transaction/reports_ledgersreports`,
    name: "ledgerreports",
    element: LedgerReports,
  },
  {
    path: `/dashboard/transaction/reports_trailbalancereports`,
    name: "trailbalance",
    element: TrailBalance,
  },
  {
    path: `/dashboard/transaction/reports_profitloss`,
    name: "profitloss",
    element: ProfitLoss,
  },
  {
    path: `/dashboard/transaction/reports_balancesheet`,
    name: "balanceheet",
    element: BalanceSheet,
  },
  {
    path: `/dashboard/transaction/reports_freightregister`,
    name: "freightregister",
    element: FreightRegister,
  },

  //GST
  {
    path: `/dashboard/transaction/gst_gstAutghentication`,
    name: "gstauthentication",
    element: GstAuthentication,
  },
  {
    path: `/dashboard/transaction/gst_allgstservices`,
    name: "gstservices",
    element: AllGstServices,
  },
  {
    path: `/dashboard/transaction/gst_gstr1Services`,
    name: "gstr1services",
    element: GSTR1Services,
  },
  {
    path: `/dashboard/gst/gst_createchallan`,
    name: "createchallan",
    element: CreateChallan,
  },
  //Eway Bill
  {
    path: `/dashboard/ewaybill/GenerateEwayBillGrid`,
    name: "GenerateEwayBillGrid",
    element: GenerateEwayBillGrid,
  },
  {
    path: `/dashboard/ewaybill/GenerateEwayBill`,
    name: "GenerateEwayBill",
    element: GenerateEwayBill,
  },
  {
    path: `/dashboard/ewaybill/BillsByDateGrid`,
    name: "BillsByDateGrid",
    element: BillsByDateGrid,
  },
  {
    path: `/dashboard/ewaybill/TransporterByDateGrid`,
    name: "TransporterByDateGrid",
    element: TransporterByDateGrid,
  },
  {
    path: `/dashboard/ewaybill/TransporterByStateGrid`,
    name: "TransporterByStateGrid",
    element: TransporterByStateGrid,
  },
  //CancelEwayBill
  {
    path: `/dashboard/ewaybill/CancelEwayBill`,
    name: "CancelEwayBill",
    element: CancelEwayBill,
  },
  //MultiReport Printing
  {
    path: `/dashboard/transaction/ewport_reportprinting`,
    name: "reportprinting",
    element: ReportPrinting,
  },
];
export default TransWondersRoutes;
