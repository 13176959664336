import SharedPath from "./helper/SharedPath";
let rootPath = SharedPath.toLocaleLowerCase();
const Config = {
  dahboardUrl: `/dashboard/dshboardhome`,
  signInPath: `/login_page`,
  superadminControlUrl: `/admin_control`,
  REDIRECTPAGE: "https://accountswonders.in",
  WalletUrl: "https://api.accountswonders.in/PayUMoneyPayment/PaymentHandle",
  SERVER_URL: "https://api.accountswonders.in",
};
export default Config;
//https://api.transwonders.com
//https://api.accountswonders.in/
