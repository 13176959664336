import { handleResponse } from "../../../helper/handle-response";
import Config from "../../../Config";
import { BehaviorSubject } from "rxjs";
import { authHeader } from "../../../helper/auth-header";
import { useHistory } from "react-router-dom";
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
export const AuthenticationService = {
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  login,
  logout,
  getRoles,
  checkUsername,
  createUserWithCompany,
  createUser,
  changePassword,
  loginForClient,
};
function login(userName, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userName, password }),
  };
  return fetch(`${Config.SERVER_URL}/auth/login`, requestOptions)
    .then(handleResponse)
    .then(async (user) => {
      await localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}
function getRoles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${Config.SERVER_URL}/auth/getroles`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function checkUsername(userName) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userName }),
  };
  return fetch(`${Config.SERVER_URL}/auth/checkuserexists`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function createUserWithCompany(
  fullName,
  userName,
  email,
  password,
  confirmPassword,
  phoneNumber,
  role,
  companyName,
  pan,
  address,
  countryId,
  stateId,
  pincode,
  roundOffFactorId
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      fullName,
      userName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      role,
      companyName,
      address,
      pan,
      address,
      countryId,
      stateId,
      pincode,
      roundOffFactorId,
    }),
  };

  return fetch(
    `${Config.SERVER_URL}/auth/registerwithnewcompany`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
function createUser(
  fullName,
  userName,
  password,
  confirmPassword,
  phoneNumber,
  role,
  companyId
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      fullName,
      userName,
      password,
      confirmPassword,
      phoneNumber,
      role,
      companyId,
    }),
  };
  return fetch(`${Config.SERVER_URL}/auth/registeruser`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
function changePassword(oldPassword, newPassword, confirmPassword) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
  };

  return fetch(`${Config.SERVER_URL}/auth/changepassword`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
async function logout() {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("isAuthenticate");
  localStorage.removeItem("gstTransactionId");
  localStorage.removeItem("company");
  currentUserSubject.next(null);
  window.location.href = "/login_page";
}

function loginForClient(companyHash) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ companyHash }),
  };
  return fetch(`${Config.SERVER_URL}/auth/LoginForClient`, requestOptions)
    .then(handleResponse)
    .then(async (user) => {
      await localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      return user;
    });
}
