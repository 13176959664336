import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";
import LoadingOverlay from "react-loading-overlay-nextgen";

class  WebSiteLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div
        style={{  marginTop: "320px", marginLeft: "50px" }}
      >
        <LoadingOverlay active={true} spinner={<Loader />}></LoadingOverlay>
      </div>
    );
  }
}

export default WebSiteLoader;

class Loader extends React.Component {
  render() {
    return (
      <PropagateLoader
        color={"#4B49B6"}
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
    );
  }
}
