import { handleResponse } from "../../../helper/handle-response";
import Config from "../../../Config";
import { authHeader } from "../../../helper/auth-header";
export const WalletServices = {
  getPaymentHash,
  getCurrentWalletInfo,
  getPricingForGstSearch,
  getPricingForEInvoice,
  addTransaction,
  getTransactionHistory,
  getPricingForGstr2A,
  getPricingForGstr2B,
  getPricingForGenerateEwayBill,
  getPricingForGstr1File
};
function getTransactionHistory(startDate, endDate) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      startDate,
      endDate,
    }),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetTransactionHistory`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
function getPaymentHash(amount, surl, furl) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ amount, surl, furl }),
  };

  return fetch(`${Config.SERVER_URL}/wallet/getpaymenthash`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getCurrentWalletInfo() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${Config.SERVER_URL}/Wallet/GetCurrentWalletInfo`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForGstSearch() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForGstSearch`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForEInvoice() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForEInvoice`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function addTransaction(ServicePricingId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ ServicePricingId }),
  };

  return fetch(`${Config.SERVER_URL}/wallet/AddTransaction`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForGstr2A() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForGstr2A`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForGstr2B() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForGstr2B`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForGenerateEwayBill() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForGenerateEwayBill`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getPricingForGstr1File() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${Config.SERVER_URL}/wallet/GetPricingForGstr1File`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}