const _steps = [
  {
    selector: ".companyDetails-step",
    content: (
      <div style={{ color: "#5856D6" }}>fill the company basic details</div>
    ),
  },
  {
    selector: ".companyDetailsGst-step",
    content: (
      <div style={{ color: "#5856D6" }}>fill the company GST details</div>
    ),
  },

  {
    selector: ".companyDetailsE-invoice-step",
    content: (
      <div style={{ color: "#5856D6" }}>fill the company E-Invoice details</div>
    ),
  },

  {
    selector: ".companyDetailsE-WayBil-step",
    content: (
      <div style={{ color: "#5856D6" }}>fill the company E-way-Bill details</div>
    ),
  },

  {
    selector: ".companyDetailsTDS-step",
    content: (
      <div style={{ color: "#5856D6" }}>fill the company TDS details</div>
    ),
  },
];
export default _steps;