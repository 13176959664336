import React from "react";
const DashBoardDocumentation = React.lazy(() =>
  import("../pages/shared/Documentataion/DashBoardDocumentation")
);
const CompanyDocumentation = React.lazy(() =>
  import("../pages/shared/Documentataion/CompanyDocumentation")
);

const GroupsDocumentation =  React.lazy(() =>
import("../pages/shared/Documentataion/GroupsDocumentation")
);

const LedgerDocumentation =  React.lazy(() =>
import("../pages/shared/Documentataion/LedgerDocumentation")
);

const RetiredLedgerDocumentation =  React.lazy(() =>
import("../pages/shared/Documentataion/RetiredLedgerDocumentation")
);
const CostCenterDocumentation =  React.lazy(() =>
import("../pages/shared/Documentataion/CostCenterDocumentation")
);
const DocumentRoutes = [
  {
    path: "/document/dashboard",
    name: "documentdashboard",
    element: DashBoardDocumentation,
  },
  {
    path: "/document/company",
    name: "documentcompany",
    element: CompanyDocumentation,
  },
  {
    path: "/document/groups",
    name: "documentgroups",
    element: GroupsDocumentation,
  },
  {
    path: "/document/ledger",
    name: "documentledger",
    element: LedgerDocumentation,
  },
  {
    path: "/document/RetiredLedger",
    name: "documentRetiredLedger",
    element: RetiredLedgerDocumentation,
  },
  {
    path: "/document/CostCenter",
    name: "documentCostCenter",
    element: CostCenterDocumentation,
  },
];

export default DocumentRoutes;
